<template>
    <div class="confirm-modal">
        <h1 v-html="$content(params.title)"></h1>
        <div class="confirm-modal_body">
            <button class="confirm-button" @click="$modal.close()">{{ $content('answer-no') }}</button>
            <button class="confirm-button confirm-button_action" @click="onConfirm">{{ $content('answer-yes') }}</button>
        </div>
    </div>
</template>

<script>
import ProjectButton from "../Buttons/ProjectButton";
export default {
    name: "Confirm",
    components: {ProjectButton},
    methods: {
        onConfirm() {
            if (this.params) {
                this.$http(this.params.confirm.repo)[this.params.confirm.method](this.params.confirm.data)
                    .then(res => {
                        this.$root.$emit('newsDeleted');
                        this.$modal.close();
                    })
                    .catch(() => this.$modal.close())
            }
        }
    },
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        }
    }
}
</script>

<style lang="scss">
    @import "../../styles/mixins";

    .confirm-modal {
        background: white;
        border-radius: 10px;
        padding: 40px 25px 20px 25px;
        width: 400px;

        &_body {
            display: flex;
            justify-content: flex-end;
        }

        & h1 {
            font-size: 22px;
            color: var(--main-app);
            margin-bottom: 25px;
        }
    }
</style>